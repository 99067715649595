import { Link } from "react-router-dom";
const NotificationsPanel = ({notifications, setShowNotificationsPanel}) => {

  const closePanel = () => {
    setShowNotificationsPanel(false)
  }
  return (
    <div className='notifications-panel' onClick={closePanel}>
        {notifications.total ===  0 && <span>Aucune notification</span>}
        {notifications.pending_orders > 0 && <Link to='/'>{notifications.pending_orders} nouvelles commandes</Link>}
        {notifications.late_orders_unprocessed > 0 && <Link to='/orders_late'>{notifications.late_orders_unprocessed} retard(s) à commenter</Link>}
        {notifications.cancellation_requests > 0 && <Link to='/cancellations_requests'>{notifications.cancellation_requests} demande(s) d'annulation</Link>}
    </div>
  )
}

export default NotificationsPanel;