import React from 'react';
import { useContext } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import '../css/main.css';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Orders from './Orders';
import Shipments from './Shipments';
import SearchResults from './SearchResults';
import SidebarContext from '../context/SidebarContext';
import SearchResultsContext from '../context/SearchTermContext';
import Stats from './Stats';
import Suppliers from './Suppliers';
import Logo from '../static/img/epytis_logo_simplified.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Main = () => {

    const { collapsed } = useContext(SidebarContext);
    const { setSearchResults } = useContext(SearchResultsContext);
    const clearSearchResults = () => {
        setSearchResults([]);
      };

    const notify = (text, status="success") => {
        if (status === 'success') {
        toast.success(text, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "custom-toast",
        });}
        else {
            toast.error(text, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "custom-toast",
              });
        }

      };

    return (
        <div>
            <div className={`rectangle ${collapsed ? 'collapsed' : ''}`}>
            {((window.location.hostname === 'localhost') &&
                    (<Link to ="/"><div className="logo"><img src="https://www.pngfind.com/pngs/m/170-1706361_web-development-icon-web-development-logo-png-transparent.png" alt="Development Logo" width="80px" /></div></Link>))
            ||
                (<Link to ="/"><div className="logo"><img src={Logo} alt="Epytis Logo" width="80px" /></div></Link>)
            }
            </div>
        <Topbar clearSearchResults={clearSearchResults} />
        <Sidebar />
            <div className={`main ${collapsed ? 'collapsed' : ''}`}>
                <Routes>
                    <Route path="/" element={<Orders status_filter={'a_saisir'} notify={notify}/>} />
                    <Route path="/orders_waiting" element={<Orders status_filter={'en_commande'} notify={notify}/>} />
                    <Route path="/orders_late" element={<Orders status_filter={'late'} notify={notify}/>} />
                    <Route path="/orders_incomplete" element={<Orders status_filter={'incomplete'} notify={notify}/>} />
                    <Route path="/orders_to_send" element={<Orders status_filter={'a_envoyer'} notify={notify}/>} />
                    <Route path="/direct_orders" element={<Orders status_filter={'direct'} notify={notify}/>} />
                    <Route path="/orders_unavailable" element={<Orders status_filter={'unavailable'} notify={notify}/>} />
                    <Route path="/cancellations_requests" element={<Orders status_filter={'pending_cancelation'} notify={notify}/>} />
                    <Route path="/shipments" element={<Shipments notify={notify}/>} />
                    <Route path="/search" element={<SearchResults notify={notify}/>} />
                    <Route path="/stats" element={<Stats notify={notify}/>} />
                    <Route path="/suppliers" element={<Suppliers notify={notify}/>} />
                </Routes>
            </div>
            <ToastContainer   autoClose={2000} hideProgressBar />
        </div>
    );
};

export default Main;