import React from 'react';
import { useContext } from 'react';
import '../css/main.css'; 
import SidebarItem from './SidebarItem';
import SidebarContext from '../context/SidebarContext';
import { NotificationsContext } from '../context/NotificationsContext';


const Sidebar = () => {
    const { collapsed, toggleCollapsed } = useContext(SidebarContext);
    const { notifications } = useContext(NotificationsContext);
    let a_saisir = notifications && notifications.pending_orders;
    let annulations_demandees = notifications && notifications.cancellation_requests;
    let en_attente =  notifications && notifications.waiting_orders;
    let a_envoyer = notifications && notifications.to_send_orders;
    let en_retard = notifications && notifications.late_orders;

    


    const menuItems = [
        { icon: 'fas fa-shopping-cart', text: 'A SAISIR' + (a_saisir && a_saisir > 0 ? ` (${a_saisir})` : ''), to: '/' },
        { icon: 'fas fa-hourglass-half', text: 'EN ATTENTE' + (en_attente && en_attente > 0 ? ` (${en_attente})` : ''), to: '/orders_waiting' },
        { icon: 'fas fa-clock', text: 'EN RETARD' + (en_retard && en_retard > 0 ? ` (${en_retard})` : ''), to: '/orders_late' },
        { icon: 'fas fa-pause-circle', text: 'INCOMPLETES', to: '/orders_incomplete' },
        { icon: 'fas fa-check-square', text: 'A ENVOYER' + (a_envoyer && a_envoyer > 0 ? ` (${a_envoyer})` : ''), to: '/orders_to_send' },
        { icon: 'fas fa-mail-bulk', text: 'ENVOIS DIRECTS', to: '/direct_orders' },
        { icon: 'fas fa-window-close', text: 'INDISPONIBLE', to: '/orders_unavailable' },
        { icon: 'fas fa-times-circle', text: 'ANNULATIONS' + (annulations_demandees && annulations_demandees > 0 ? ` (${annulations_demandees})` : ''), to: '/cancellations_requests' },
        { icon: 'fas fa-plane', text: 'ENVOIS', to: '/shipments' },
        { icon: 'fas fa-truck', text: 'FOURNISSEURS', to: '/suppliers' },
        { icon: 'fas fa-chart-line', text: 'STATISTIQUES', to: '/stats' },
      ];

    return (
        <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
        <i className="fas fa-bars sidebar-btn" onClick={toggleCollapsed}></i>
        <div className={`sidebar-content ${collapsed ? 'collapsed' : ''}`}>
        {menuItems.map((item) => (
          <SidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            to={item.to}
          />
        ))}
        </div>
        <div className="sidebar-bottom">
        </div>
        </div>
    );
};

export default Sidebar;
